import React, { FC, ReactElement } from 'react'

import * as Styled from './styles/ListGroup.style'

import { ListGroupProps } from './ListGroup.types'

const ListGroup: FC<ListGroupProps> = ({
  type = 'unordered',
  listStyle = 'none',
  className,
  role,
  children,
  id,
}: ListGroupProps): ReactElement => {
  return (
    <Styled.ListGroup id={id} as={type === 'unordered' ? 'ul' : 'ol'} className={className} role={role}>
      {React.Children.map(children, (child) => {
        if (!child) return
        return React.cloneElement(child, {
          listType: type,
          listStyle,
        })
      })}
    </Styled.ListGroup>
  )
}

export default ListGroup
