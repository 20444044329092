import { css, FlattenSimpleInterpolation } from 'styled-components'

import { MixinSrOnly } from './srOnly.types'

import createMixin from '../utils/createMixin'

export default createMixin(
  (): FlattenSimpleInterpolation => css`
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
  `,
) as MixinSrOnly
